import React, { useContext } from "react";
import * as prismic from "@prismicio/client";
import { Box, Text } from "@chakra-ui/react";
import Link from "next/link";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import styles from "./ParallaxCta.module.css";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

/**
 * Props for `ParallaxCta`.
 */
export type ParallaxCtaProps = SliceComponentProps<Content.ParallaxCtaSlice>;

/**
 * Component for "ParallaxCta" Slices.
 */
const ParallaxCta = ({ slice }: ParallaxCtaProps): JSX.Element => {
  const backgroundImage = slice.primary.background_image?.url;
  const fallbackImage = "/fallback-image-1.jpeg";

  const divStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${
      backgroundImage ? backgroundImage : fallbackImage
    })`,
    padding: "125px 0",
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={[styles.parallaxItem, "parallaxImageHelper"].join(" ")}
        style={divStyle}
      >
        <FadeInWhenVisible>
          <div className="container">
            {prismic.asText(slice.primary.title) && (
              <Text textStyle="h2" as="h2" color="white" marginBottom="30px">
                {prismic.asText(slice.primary.title)}
              </Text>
            )}

            {prismic.asText(slice.primary.subtitle) && (
              <Text textStyle="p" color="white">
                {prismic.asText(slice.primary.subtitle)}
              </Text>
            )}
            {slice.primary.show_modal_cta ? (
              <Box
                marginTop="75px"
                display={"flex"}
                gap="20px"
                justifyContent={"center"}
              >
                <ScrollLink
                  className="button   button--large"
                  to="calendlyBooking"
                  smooth
                  duration={500}
                >
                  Arrange a Call
                </ScrollLink>
              </Box>
            ) : (
              <>
                {slice.primary.show_cta &&
                  prismic.asText(slice.primary.cta_text) &&
                  prismic.asLink(slice.primary.cta_link) && (
                    <Box
                      marginTop="75px"
                      display={"flex"}
                      gap="20px"
                      justifyContent={"center"}
                    >
                      <Link
                        className="button button--large"
                        href={prismic.asLink(slice.primary.cta_link)}
                      >
                        {prismic.asText(slice.primary.cta_text)}
                      </Link>
                    </Box>
                  )}
              </>
            )}
          </div>
        </FadeInWhenVisible>
      </div>
    </div>
  );
};

export default ParallaxCta;
